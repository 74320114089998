import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NotFound from './NotFound';
import { Link } from 'react-router-dom';
import Avatar from './Avatar';
import moment from 'moment';
import { isReadNotify, deleteAllNotifies } from '../redux/actions/notifyAction';
// import { isReadNotify, NOTIFY_TYPES, deleteAllNotifies } from '../redux/actions/notifyAction';

const NotifyModal = () => {
    const { auth, notify } = useSelector(state => state);
    const dispatch = useDispatch();

    const handleIsRead = (msg) => {
        if (msg) {
            dispatch(isReadNotify({ msg, auth }));
        }
    };

    // const handleSound = () => {
    //     dispatch({ type: NOTIFY_TYPES.UPDATE_SOUND, payload: !notify.sound });
    // };

    const handleDeleteAll = () => {
        const unreadNotices = notify.data?.filter(item => item.isRead === false) || [];
        if (unreadNotices.length === 0) return dispatch(deleteAllNotifies(auth?.token));

        if (window.confirm(`You have ${unreadNotices.length} unread notices. Are you sure you want to delete all?`)) {
            return dispatch(deleteAllNotifies(auth?.token));
        }
    };

    return (
        <div className="notification" style={{ minWidth: '350px' }}>
            <div className="d-flex justify-content-between align-items-center px-3">
                <h5 style={{ color: "#fff500", marginTop: "5%" }}><b>Notification</b></h5>
                {/* {
                    notify.sound 
                    ? <i className="fas fa-bell text-danger" 
                    style={{fontSize: '1.2rem', cursor: 'pointer'}}
                    onClick={handleSound} />

                    : <i className="fas fa-bell-slash text-danger"
                    style={{fontSize: '1.2rem', cursor: 'pointer'}}
                    onClick={handleSound} />
                } */}

                <i className="fas fa-trash text-danger"
                    style={{ fontSize: '1.2rem', cursor: 'pointer', marginTop: "5%" }}
                    onClick={handleDeleteAll}>
                </i>
            </div>

            <hr className="mt-0" />

            {
                (!notify.data || notify.data.length === 0) &&
                <NotFound />
            }

            <div style={{ maxHeight: 'calc(100vh - 250px)', overflow: 'auto' }}>
                {
                    notify.data?.map((msg, index) => (
                        <div
                            key={index}
                            className="px-2 mb-3"
                            style={{
                                backgroundColor: msg?.isRead ? 'transparent' : '#440e74',
                                paddingTop: msg?.isRead ? 'transparent' : '2%'
                            }}
                        >
                            <Link to={msg?.url || "#"}
                                className="d-flex text-dark align-items-center"
                                onClick={() => handleIsRead(msg)}
                                style={{ textDecoration: "none" }}
                            >
                                <Avatar src={msg?.user?.avatar || '/default-avatar.png'} size="big-avatar" />

                                <div className="mx-1 flex-fill">
                                    <div style={{ color: "white" }}>
                                        <strong className="mr-1">{msg?.user?.username || 'Unknown User'}</strong>
                                        <span>{msg?.text || 'No details available'}</span>
                                    </div>
                                    {msg?.content && <small style={{ color: "white" }}>{msg.content.slice(0, 20)}...</small>}
                                </div>

                                {/* {
                                    msg.image &&
                                    <div style={{width: '30px', textDecoration: "none"}}>
                                        {
                                            msg.image.match(/video/i)
                                            ? <video src={msg.image} width="100%" />
                                            : <Avatar src={msg.image} size="medium-avatar" />
                                        }
                                    </div>
                                } */}

                            </Link>

                            <small className="text-muted d-flex justify-content-between px-2" style={{ textDecoration: "none" }}>
                                <span style={{ color: "white", marginTop: "2%" }}>
                                    {msg?.createdAt ? moment(msg.createdAt).fromNow() : 'Unknown time'}
                                </span>
                                {
                                    !msg?.isRead &&
                                    <i className="fas fa-circle" style={{
                                        textDecoration: "none",
                                        color: "#fff500",
                                        marginTop: "-5%"
                                    }} />
                                }
                            </small>
                            <hr />
                        </div>
                    ))
                }
            </div>

            {/* <hr className="my-1" />
            <div className="text-right text-danger mr-2" style={{ cursor: 'pointer' }}
                onClick={handleDeleteAll}>
                <b> Delete All </b>
            </div> */}
        </div>
    );
};

export default NotifyModal;