import { GLOBALTYPES } from './globalTypes';
import { postDataAPI } from '../../utils/fetchData';
import valid from '../../utils/valid';

export const login = (data) => async (dispatch) => {
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
        const res = await postDataAPI('login', data);

        dispatch({
            type: GLOBALTYPES.AUTH,
            payload: {
                token: res.data.access_token,
                user: res.data.user,
            },
        });

        localStorage.setItem('firstLogin', true);

        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { success: res.data.msg, loading: false },
        });

        setTimeout(() => {
            dispatch({ type: GLOBALTYPES.CLEAR_ALERT });
        }, 5000);
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err.response?.data?.msg || 'Something went wrong.',
                loading: false,
            },
        });

        setTimeout(() => {
            dispatch({ type: GLOBALTYPES.CLEAR_ALERT });
        }, 5000);
    }
};

export const forgotPassword = (email) => async (dispatch) => {
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

        const res = await postDataAPI('forgot_password', { email });

        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { loading: false, success: res.data.msg },
        });

        setTimeout(() => {
            dispatch({ type: GLOBALTYPES.CLEAR_ALERT });
        }, 5000);
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err.response?.data?.msg || 'Something went wrong.',
                loading: false,
            },
        });

        setTimeout(() => {
            dispatch({ type: GLOBALTYPES.CLEAR_ALERT });
        }, 5000);
    }
};

export const resetPassword = (id, token, password) => async (dispatch) => {
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

        const res = await postDataAPI(`reset_password/${id}/${token}`, { password });

        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { success: res.data.msg, loading: false },
        });

        setTimeout(() => {
            dispatch({ type: GLOBALTYPES.CLEAR_ALERT });
        }, 5000);
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err.response?.data?.msg || 'Something went wrong.',
                loading: false,
            },
        });

        setTimeout(() => {
            dispatch({ type: GLOBALTYPES.CLEAR_ALERT });
        }, 5000);
    }
};

export const refreshToken = () => async (dispatch) => {
    const firstLogin = localStorage.getItem('firstLogin');
    if (firstLogin) {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

        try {
            const res = await postDataAPI('refresh_token');
            dispatch({
                type: GLOBALTYPES.AUTH,
                payload: {
                    token: res.data.access_token,
                    user: res.data.user,
                },
            });

            dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });

            setTimeout(() => {
                dispatch({ type: GLOBALTYPES.CLEAR_ALERT });
            }, 5000);
        } catch (err) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: err.response?.data?.msg || 'Something went wrong.',
                    loading: false,
                },
            });

            setTimeout(() => {
                dispatch({ type: GLOBALTYPES.CLEAR_ALERT });
            }, 5000);
        }
    }
};

export const register = (data) => async (dispatch) => {
    const check = valid(data);
    if (check.errLength > 0)
        return dispatch({
            type: GLOBALTYPES.ALERT,
            payload: check.errMsg,
        });

    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

        const res = await postDataAPI('register', data);

        dispatch({
            type: GLOBALTYPES.AUTH,
            payload: {
                token: res.data.access_token,
                user: res.data.user,
            },
        });

        localStorage.setItem('firstLogin', true);

        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { success: res.data.msg, loading: false },
        });

        setTimeout(() => {
            dispatch({ type: GLOBALTYPES.CLEAR_ALERT });
        }, 5000);
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err.response?.data?.msg || 'Something went wrong.',
                loading: false,
            },
        });

        setTimeout(() => {
            dispatch({ type: GLOBALTYPES.CLEAR_ALERT });
        }, 5000);
    }
};

export const logout = () => async (dispatch) => {
    try {
        localStorage.removeItem('firstLogin');
        await postDataAPI('logout');

        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { success: 'Logged out successfully.' },
        });

        setTimeout(() => {
            dispatch({ type: GLOBALTYPES.CLEAR_ALERT });
        }, 5000);

        window.location.href = '/';
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err.response?.data?.msg || 'Something went wrong.',
            },
        });

        setTimeout(() => {
            dispatch({ type: GLOBALTYPES.CLEAR_ALERT });
        }, 5000);
    }
};