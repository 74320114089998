import React, { useState, useEffect, useRef } from 'react'
import UserCard from '../UserCard'
import { useSelector, useDispatch } from 'react-redux'
import { getDataAPI } from '../../utils/fetchData'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { useHistory, useParams } from 'react-router-dom'
import { MESS_TYPES, getConversations } from '../../redux/actions/messageAction'

const LeftSide = () => {
    const { auth, message, online } = useSelector(state => state)
    const dispatch = useDispatch()

    const [search, setSearch] = useState('')
    const [searchUsers, setSearchUsers] = useState([])

    const history = useHistory()
    const { id } = useParams()

    const pageEnd = useRef()
    const [page, setPage] = useState(0)

    const handleSearch = async (value) => {
        setSearch(value);
        if (!value) return setSearchUsers([]);
    
        try {
            const res = await getDataAPI(`search?username=${value}`, auth.token);
    
            // Filter out the logged-in user's ID
            const filteredUsers = res.data.users.filter(user => user._id !== auth.user._id);
            setSearchUsers(filteredUsers);
        } catch (err) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: err.response?.data?.msg || 'Error fetching users' }
            });
        }
    };    

    const handleAddUser = (user) => {
        setSearch('')
        setSearchUsers([])
        dispatch({ type: MESS_TYPES.ADD_USER, payload: { ...user, text: '', media: [] } })
        dispatch({ type: MESS_TYPES.CHECK_ONLINE_OFFLINE, payload: online })
        return history.push(`/message/${user._id}`)
    }

    const isActive = (user) => {
        if (id === user._id) return 'active'
        return ''
    }

    useEffect(() => {
        if (message.firstLoad) return
        dispatch(getConversations({ auth }))
    }, [dispatch, auth, message.firstLoad])

    // Load More
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setPage(p => p + 1)
            }
        }, {
            threshold: 0.1
        })

        observer.observe(pageEnd.current)
    }, [setPage])

    useEffect(() => {
        if (message.resultUsers >= (page - 1) * 9 && page > 1) {
            dispatch(getConversations({ auth, page }))
        }
    }, [message.resultUsers, page, auth, dispatch])

    // Check User Online - Offline
    useEffect(() => {
        if (message.firstLoad) {
            dispatch({ type: MESS_TYPES.CHECK_ONLINE_OFFLINE, payload: online })
        }
    }, [online, message.firstLoad, dispatch])

    return (
        <div>
            <form className="message_header" onSubmit={e => e.preventDefault()}>
                <input
                    type="text"
                    value={search}
                    className='input'
                    placeholder="Search your friend to chat"
                    onChange={e => handleSearch(e.target.value)}
                />
            </form>

            <div className="message_chat_list" style={{ borderRight: "2px solid #27004b" }}>
                {
                    searchUsers.length !== 0
                        ? <div>
                            {
                                searchUsers.map(user => (
                                    <div key={user._id} className={`message_user ${isActive(user)}`}
                                        onClick={() => handleAddUser(user)}>
                                        <UserCard user={user} />
                                    </div>
                                ))
                            }
                        </div>
                        : <div>
                            {
                                message.users.map(user => (
                                    <div key={user._id} className={`message_user ${isActive(user)}`}
                                        onClick={() => handleAddUser(user)}>
                                        <UserCard className="user_list" user={user} msg={true}>
                                            {
                                                user.online
                                                    ? <i className="fas fa-circle text-success" />
                                                    : auth.user.following.find(item =>
                                                        item._id === user._id
                                                    ) && <i className="" />
                                            }
                                        </UserCard>
                                    </div>
                                ))
                            }
                        </div>
                }

                <button ref={pageEnd} style={{ opacity: 0 }}>Load More</button>
            </div>
        </div>
    )
}

export default LeftSide