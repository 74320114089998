// import React from 'react';
// import { Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';

// const AboutDetail = (props) => {
//     return (
//         <div>
//             <Card style={{ marginTop: "10px", border: "none" }}>
//                 <CardImg className="img" top src={props.info.poster} alt={props.info.name} />
//                 <CardBody style={{ textAlign: "left" }}>
//                     <CardTitle className='title'> <b> {props.info.title_1} </b> </CardTitle>
//                     <CardText className='description'>
//                         {props.info.description_1}
//                     </CardText>
//                     <br />

//                     <CardTitle className='title'> <b> {props.info.title_2} </b> </CardTitle>
//                     <CardText className='description'>
//                         {props.info.description_2}
//                     </CardText>
//                     <br />

//                     <CardTitle className='title'> <b> {props.info.title_3} </b> </CardTitle>
//                     <CardText className='description'>
//                         {props.info.description_3}
//                     </CardText>
//                     <br />

//                     <CardTitle className='title'> <b> {props.info.title_4} </b> </CardTitle>
//                     <CardText className='description'>
//                         {props.info.description_4}
//                     </CardText>
//                     <br />

//                     <CardTitle style={{fontSize: "25px", color: "#400079", textAlign: "center"}}> <b> Follow Foodbyte! </b> </CardTitle> <br />

//                     <div className='followMe'>
//                         <a href="https://www.facebook.com/foodbyteapp" target={"_blank"} rel="noreferrer" className='facebook'> <span className="fa fa-facebook fa-2x text-primary"> </span> </a>
//                         {/* <a href="https://www.instagram.com/foodbyteapp" target={"_blank"} rel="noreferrer" className='instagram'> <span className="fa fa-instagram fa-2x" style={{color: "rgb(255, 0, 106)"}}> </span> </a> */}
//                         {/* <a href="https://twitter.com/ahmedinsiders" target={"_blank"} rel="noreferrer" className='twitter'> <span className="fa fa-twitter fa-2x text-primary"> </span> </a> */}
//                         {/* <a href="https://www.linkedin.com/in/ahmedinsider/" target={"_blank"} rel="noreferrer" className='linkedIn'> <span className="fa fa-linkedin fa-2x text-primary"> </span> </a> */}
//                     </div>

//                 </CardBody>
//             </Card>
//         </div>
//     );
// }

// export default AboutDetail;



import React from 'react';

const AboutDetail = () => {
    return (
        <div className='about_detail'>
            <img src="assets/images/fbPoster.PNG" alt="foodbyte poster" />
            <h2><b>What is Foodbyte?</b></h2>
            <p>Foodbyte is an ultimate social media platform for food lovers who love to share their daily foodie activities with their family, friends, or the whole world. They can easily sell and buy food from this platform. Also, one can participate in upcoming events as well. The well-wisher can also donate his valuable money to grow this platform.</p>

            <h2><b>Vision</b></h2>
            <p>There is nobody who does not love food. Food not only saves lives but also brings happiness to your heart. So, the vision of Foodbyte is to make it the world's largest online foodie community where people can buy and sell food as well as share their daily food-related activities (we call it happiness) with the whole world. Anyone can be a part of this and contribute to building this network largest and most amazing.</p>

            <h2><b>Mission</b></h2>
            <p>An empty belly is a devil's mind and a mind is hard to be productive with an empty stomach. So, the mission of Foodbyte is to diminish the hunger problem in the world. The profit Foobdyte earns, will donate to those people who are in serious need of food. As a result, we believe the famine situation can be reduced the society, and from society to society our mission is to eliminate the hunger problem from the world. This is what we call the 'Foodbyte Theory'.</p>

            <h3><b>Opportunity</b></h3>
            <p>Foodbyte will bring many opportunities soon such as home delivery of ordered food which will save time and unwanted efforts of a customer. Poor people who can not earn their livelihood with food will get an amazing opportunity to bring food to their homes and share happiness with their families. Also, job vacancies will launch as soon as possible. As a result, the ratio of unemployment problems will reduce gradually.</p>
        </div>
    );
}

export default AboutDetail;