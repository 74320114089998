import React, { useState, useEffect } from 'react';
import PrivacyDetail from '../settings/Privacy Policy/PrivacyDetail';
import AboutDetail from '../settings/About/AboutDetail';
import TermsDetail from './Terms and Condition/TermsDetail';

const Settings = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Check for mobile view

    // Handle window resize event
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        
        // Set default option for desktop when component is mounted
        if (!isMobile) {
            setSelectedOption('AboutDetail');
        }

        return () => window.removeEventListener('resize', handleResize);
    }, [isMobile]);

    // Render content based on the selected option
    const renderContent = () => {
        switch (selectedOption) {
            case 'AboutDetail':
                return <AboutDetail />;
            case 'TermsDetail':
                return <TermsDetail />;
            case 'PrivacyDetail':
                return <PrivacyDetail />;
            default:
                return null;
        }
    };

    const handleOptionClick = (option) => {
        if (isMobile) {
            setSelectedOption(option === selectedOption ? null : option); // Toggle on mobile
        } else {
            setSelectedOption(option); // Direct selection on desktop
        }
    };

    return (
        <div className="settings-container">
            <div className="settings-menu">
                {/* Accordion Menu for mobile */}
                <button
                    className={`menu-option ${selectedOption === 'AboutDetail' ? 'active' : ''}`}
                    onClick={() => handleOptionClick('AboutDetail')}
                >
                    About
                </button>
                <div className={`accordion-content ${selectedOption === 'AboutDetail' ? 'open' : ''}`}>
                    {isMobile && selectedOption === 'AboutDetail' && <AboutDetail />}
                </div>

                <button
                    className={`menu-option ${selectedOption === 'TermsDetail' ? 'active' : ''}`}
                    onClick={() => handleOptionClick('TermsDetail')}
                >
                    Terms & Conditions
                </button>
                <div className={`accordion-content ${selectedOption === 'TermsDetail' ? 'open' : ''}`}>
                    {isMobile && selectedOption === 'TermsDetail' && <TermsDetail />}
                </div>

                <button
                    className={`menu-option ${selectedOption === 'PrivacyDetail' ? 'active' : ''}`}
                    onClick={() => handleOptionClick('PrivacyDetail')}
                >
                    Privacy Policy
                </button>
                <div className={`accordion-content ${selectedOption === 'PrivacyDetail' ? 'open' : ''}`}>
                    {isMobile && selectedOption === 'PrivacyDetail' && <PrivacyDetail />}
                </div>
                <br /> <br /> <br /> 
            </div>

            {/* Render selected content for mobile or desktop */}
            {(!isMobile || selectedOption) && (
                <div className="settings-content">
                    {renderContent()}
                </div>
            )}
        </div>
    );
};

export default Settings;