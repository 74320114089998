import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../redux/actions/authAction';
import { useParams, Link } from 'react-router-dom';
import Logo from "../images/foodbyte poster (transparent).png";
import Footer from "../components/footer/Footer";

const ResetPassword = () => {
    const { alert } = useSelector(state => state);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [typePass, setTypePass] = useState(false);
    const [typeCfPass, setTypeCfPass] = useState(false);
    const dispatch = useDispatch();
    const { id, token } = useParams();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!password || !confirmPassword) {
            return dispatch({
                type: 'GLOBALTYPES.ALERT',
                payload: { error: "All fields are required" }
            });
        }

        if (password !== confirmPassword) {
            return dispatch({
                type: 'GLOBALTYPES.ALERT',
                payload: { error: "Passwords do not match" }
            });
        }

        dispatch(resetPassword(id, token, password));
    };

    return (
        <div className="auth_page">
            <img className="logo" src={Logo} alt="Foodbyte Logo" />
            <div className="footer">
                <Footer />
            </div>
            <form onSubmit={handleSubmit}>
                <h3 className="text-uppercase text-center mb-4" style={{ color: "#27004b" }}>
                    <b>Reset Password</b>
                </h3>

                {/* Display Alerts */}
                {alert.loading && <div className="alert alert-info">Loading...</div>}
                {alert.success && <div className="alert alert-success">{alert.success}</div>}
                {alert.error && <div className="alert alert-danger">{alert.error}</div>}

                {/* New Password Input */}
                <div className="form-group">
                    <label htmlFor="password"><b>New Password</b></label>
                    <div className="pass">
                        <input
                            type={typePass ? "text" : "password"}
                            className="form-control"
                            id="password"
                            name="password"
                            placeholder="Enter new password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            style={{ border: "2px solid #27004b" }}
                        />
                        <small
                            onClick={() => setTypePass(!typePass)}
                            className="toggle-pass"
                            style={{ cursor: "pointer", marginRight: "2%" }}
                        >
                            <b>{typePass ? 'Hide' : 'Show'}</b>
                        </small>
                    </div>
                </div>

                {/* Confirm Password Input */}
                <div className="form-group">
                    <label htmlFor="confirmPassword"><b>Confirm Password</b></label>
                    <div className="pass">
                        <input
                            type={typeCfPass ? "text" : "password"}
                            className="form-control"
                            id="confirmPassword"
                            name="confirmPassword"
                            placeholder="Confirm your password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                            style={{ border: "2px solid #27004b" }}
                        />
                        <small
                            onClick={() => setTypeCfPass(!typeCfPass)}
                            className="toggle-pass"
                            style={{ cursor: "pointer", marginRight: "2%" }}
                        >
                            <b>{typeCfPass ? 'Hide' : 'Show'}</b>
                        </small>
                    </div>
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className="btn btn-dark w-100 mt-3"
                    disabled={alert.loading || !password || !confirmPassword}
                >
                    {alert.loading ? "Processing..." : "Reset Password"}
                </button>

                {/* Navigation Link */}
                <p className="my-2">
                    <br />
                    <b>You don't need it? </b>
                    <Link to="/" style={{ color: "#27004b", textDecoration: "none" }}>
                        <b>Get back to login</b>
                    </Link>
                </p>
            </form>
        </div>
    );
};

export default ResetPassword;