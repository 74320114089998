import React, { useState } from 'react';
import Carousel from '../../Carousel';

const CardBody = ({ post, theme }) => {
    const [readMore, setReadMore] = useState(false);

    // Function to format the content
    const formatContent = (content) => {
        if (typeof content === 'string') {
            // Replace line breaks (\n) with <br />
            return content.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ));
        }
        return content; // Fallback for unexpected types
    };

    const renderContent = () => {
        const content = post.content || ""; // Ensure content is not undefined
        if (content.length < 60) {
            return formatContent(content);
        }
        return readMore
            ? formatContent(content)
            : formatContent(content.slice(0, 60) + '...');
    };

    return (
        <div className="card_body">
            <div
                className="card_body-content"
                style={{
                    filter: theme ? 'invert(1)' : 'invert(0)',
                    color: theme ? 'white' : '#111',
                    marginTop: '5px',
                }}
            >
                <span>{renderContent()}</span>
                {post.content.length > 60 && (
                    <span
                        className="readMore"
                        onClick={() => setReadMore(!readMore)}
                        style={{ color: '#27004b', cursor: 'pointer' }}
                    >
                        <b>{readMore ? 'Hide content' : 'Read more'}</b>
                    </span>
                )}
            </div>
            {post.images.length > 0 && <Carousel images={post.images} id={post._id} />}
        </div>
    );
};

export default CardBody;