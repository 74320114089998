import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checkImage } from '../../utils/imageUpload';
import { GLOBALTYPES } from '../../redux/actions/globalTypes';
import { updateProfileUser } from '../../redux/actions/profileAction';

const EditProfile = ({ setOnEdit }) => {
    const countryList = [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Grenada",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "North Korea",
        "South Korea",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "North Macedonia",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine State",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Qatar",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Sweden",
        "Switzerland",
        "Syria",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Zambia",
        "Zimbabwe"
    ];

    const [userData, setUserData] = useState({
        fullname: '',
        email: '',
        password: '',
        country: '',
        mobile: '',
        gender: '',
        dob: '',
        address: '',
        website: '',
        story: '',
    });

    const { fullname, story } = userData;

    const [avatar, setAvatar] = useState('');
    const [typePass, setTypePass] = useState(false);

    const { auth, theme, alert } = useSelector((state) => state); // Ensure alert is fetched from Redux state
    const dispatch = useDispatch();

    useEffect(() => {
        setUserData(auth.user);
    }, [auth.user]);

    const changeAvatar = (e) => {
        const file = e.target.files[0];
        const err = checkImage(file);
        if (err) return dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err } });
        setAvatar(file);
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value !== undefined ? value : '', // Ensure value is not undefined
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check if story exceeds 100 characters
        if (story.length > 100) {
            return dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: 'Story cannot exceed 100 characters.' },
            });
        }

        // Check if fullname exceeds 25 characters
        if (fullname.length > 25) {
            return dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: 'Full name cannot exceed 25 characters.' },
            });
        }

        dispatch(updateProfileUser({ userData, avatar, auth }));
    };

    return (
        <div className="edit_profile">
            <form onSubmit={handleSubmit}>
                <button className="btn btn-danger" onClick={() => setOnEdit(false)}>
                    <b> X </b>
                </button>
                
                <div className="info_avatar">
                    <img
                        src={avatar ? URL.createObjectURL(avatar) : auth.user.avatar}
                        alt="avatar"
                        style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}
                    />
                    <span>
                        <i className="fas fa-camera" />
                        <p>
                            <b>Change</b>
                        </p>
                        <input
                            type="file"
                            name="file"
                            id="file_up"
                            accept="image/*"
                            onChange={changeAvatar}
                        />
                    </span>
                </div>

                <div className="form-group">
                    <label htmlFor="fullname"><b>Full Name</b></label>
                    <div className="position-relative">
                        <input
                            type="text"
                            className="form-control"
                            id="fullname"
                            name="fullname"
                            value={userData.fullname || ''}
                            style={{ border: '2px solid #27004b' }}
                            onChange={handleInput}
                        />
                        <small
                            className="text-danger position-absolute"
                            style={{ top: '50%', right: '5px', transform: 'translateY(-50%)' }}
                        >
                            {fullname.length}/25
                        </small>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="email"><b>Email</b></label>
                    <input
                        type="text"
                        name="email"
                        value={userData.email || ''}
                        className="form-control"
                        style={{ border: '2px solid #27004b' }}
                        onChange={handleInput}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputPassword1"><b>Password</b></label>
                    <div className="pass">
                        <input type={typePass ? "text" : "password"}
                            className="form-control" id="exampleInputPassword1"
                            onChange={handleInput} value={userData.password || ''} name="password"
                            style={{ border: "2px solid #27004b", background: `${alert.password ? '#fd2d6a14' : ''}` }} />
                        <small onClick={() => setTypePass(!typePass)} style={{ marginRight: "2%" }}>
                            <b>{typePass ? 'Hide' : 'Show'}</b>
                        </small>
                    </div>
                    <small className="form-text text-danger">
                        {alert.password ? alert.password : ''}
                    </small>
                </div>

                <div className="form-group">
                    <label htmlFor="mobile"><b>Mobile</b></label>
                    <input
                        type="number"
                        name="mobile"
                        value={userData.mobile || ''}
                        className="form-control"
                        style={{ border: '2px solid #27004b' }}
                        onChange={handleInput}
                    />
                </div>

                <label htmlFor="gender"><b>Gender</b></label>
                <div className="input-group-prepend px-0 mb-4">
                    <select
                        name="gender"
                        id="gender"
                        value={userData.gender|| ''}
                        className="custom-select text-capitalize"
                        style={{ border: '2px solid #27004b', borderRadius: "10px", fontWeight: 'bold'}}
                        onChange={handleInput}
                    >
                        <option value="male" style={{backgroundColor: '#27004b', color: '#fff500'}}> Male </option>
                        <option value="female" style={{backgroundColor: '#27004b', color: '#fff500'}}> Female </option>
                        <option value="other" style={{backgroundColor: '#27004b', color: '#fff500'}}> Other </option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="dob"><b>Date of Birth</b></label>
                    <input
                        type="date"
                        id="dob"
                        name="dob"
                        value={userData.dob || ''}
                        className="form-control"
                        style={{ border: '2px solid #27004b' }}
                        onChange={handleInput}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="country"><b>Country</b></label>
                    <select id="country" name="country" className="form-control" value={userData.country || ''} 
                        onChange={handleInput} style={{ border: "2px solid #27004b" }}>
                        <option value="" className="option" style={{backgroundColor: "#fff500", color: "#27004b", fontWeight: "bold"}}>Select your country</option>
                        {countryList.map((e, index) => (
                            <option className='option' key={index} value={e}>{e}</option>
                        ))}
                    </select>
                    <small className="form-text text-danger">
                        {alert.country ? alert.country : ''}
                    </small>
                </div>

                <div className="form-group">
                    <label htmlFor="address"><b>Address</b></label>
                    <input
                        type="text"
                        name="address"
                        value={userData.address || ''}
                        className="form-control"
                        style={{ border: '2px solid #27004b' }}
                        onChange={handleInput}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="website"><b>Website</b></label>
                    <input
                        type="text"
                        name="website"
                        value={userData.website || ''}
                        className="form-control"
                        style={{ border: '2px solid #27004b' }}
                        onChange={handleInput}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="story"><b>Story</b></label>
                    <textarea
                        name="story"
                        value={userData.story || ''}
                        cols="30"
                        rows="4"
                        className="form-control"
                        style={{ border: '2px solid #27004b' }}
                        onChange={handleInput}
                    />
                    <small className="text-danger d-block text-right">
                        {story.length}/100
                    </small>
                </div>

                <button className="btn w-100" type="submit" style={{ backgroundColor: '#27004b', color: '#fff500' }}>
                    <b> Save </b>
                </button>
            </form>
        </div>
    );
};

export default EditProfile;