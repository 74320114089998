import React from "react";
import {Scrollbars} from "react-custom-scrollbars-2";
import { useSelector } from 'react-redux'
import UserCard from "../../UserCard";
import FollowBtn from "../../FollowBtn";
import LoadingMini from "../../alert/LoadingMini";


const Suggestions = () => {
    const { suggestions } = useSelector(state => state)
    return (
        <div className="scrollbars_right">
            <Scrollbars className="scrollbars_suggestions">
            {
                suggestions.loading
                ? <LoadingMini alt="loading" className="d-block mx-auto my-4" style={{height: "300px", width: "300px", marginTop: "-55%"}} />
                : <div className="suggestions">
                {
                    suggestions.users.map(user => (
                        <UserCard key={user._id} user={user} style={{textDecoration: "none", color: "black"}}>
                            <FollowBtn user={user} />
                        </UserCard>
                    ))
                }
                </div>
            }

            </Scrollbars>

        </div>
    )
}

export default Suggestions;