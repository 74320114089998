import React from "react";
import { useSelector } from 'react-redux'
import {Scrollbars} from "react-custom-scrollbars-2";
import UserCard from "../../UserCard";
import FollowBtn from "../../FollowBtn";
import LoadIcon from '../../../images/happy loading.gif';
import "../../../styles/friendSuggestion.css";

const SearchFriend = () => {
    const { suggestions } = useSelector(state => state)
    return (
        <div className="suggestion">
            <Scrollbars className="scrollbars_suggestions">
            {
                suggestions.loading
                ? <img src={LoadIcon} alt="loading" className="d-block mx-auto my-4" style={{height: "300px", width: "300px"}} />
                : <div className="suggestions" style={{marginTop: "5%", marginBottom: "5%", marginLeft: "10%"}}>
                {
                    suggestions.users.map(user => (
                        <UserCard key={user._id} user={user} style={{textDecoration: "none", color: "black"}}>
                            <FollowBtn user={user} />
                        </UserCard>
                    ))
                }
                </div>
            }
            </Scrollbars>
        </div>
    )
}

export default SearchFriend;