import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDataAPI } from '../../utils/fetchData'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import UserCard from '../UserCard'
import LoadIcon from '../../images/loading.gif'

const Search = () => {
    const [search, setSearch] = useState('')
    const [users, setUsers] = useState([])
    const [load, setLoad] = useState(false)

    const { auth } = useSelector(state => state)
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchUsers = async () => {
            if (search.trim().length === 0) {
                setUsers([]) // Clear users if search is empty
                return
            }

            setLoad(true)
            try {
                const res = await getDataAPI(`search?username=${search}`, auth.token)
                setUsers(res.data.users)
                setLoad(false)
            } catch (err) {
                dispatch({
                    type: GLOBALTYPES.ALERT,
                    payload: { error: err.response.data.msg }
                })
                setLoad(false)
            }
        }

        if (search) {
            fetchUsers()
        } else {
            setUsers([]) // Clear users when search is empty
        }
    }, [search, auth.token, dispatch])

    const handleClose = () => {
        setSearch('')
        setUsers([])
    }

    return (
        <form className="search_form" onSubmit={(e) => e.preventDefault()}>
            <input
                type="text"
                name="search"
                value={search}
                id="search"
                title="Enter to Search"
                onChange={e => setSearch(e.target.value.toLowerCase().replace(/ /g, ''))}
                style={{ paddingLeft: "10px" }}
            />

            <div className="search_icon" style={{ opacity: search ? 0 : 0.5 }}>
                <span className="material-icons">search</span>
                <span><b>Find your friends</b></span>
            </div>

            {/* <div className="close_search" onClick={handleClose} style={{ opacity: users.length === 0 ? 0 : 1 }}>
                &times;
            </div> */}

            {load && <img className="loading" src={LoadIcon} alt="loading" />}

            <div className="users">
                {search && users.map(user => (
                    <UserCard
                        style={{ color: "white" }}
                        key={user._id}
                        user={user}
                        border="border"
                        handleClose={handleClose}
                    />
                ))}
            </div>
        </form>
    )
}

export default Search