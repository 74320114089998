import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    mobile: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await axios.post('/api/contact', formData);
      setSuccess(response.data.msg);

      setFormData({
        name: '',
        username: '',
        mobile: '',
        email: '',
        message: '',
      });

      setShowPopup(true);

      setTimeout(() => {
        setShowPopup(false);
      }, 7000);
    } catch (err) {
      setError('There was an issue submitting the form. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-form-container">
      <form className='form' onSubmit={handleSubmit}>
        <h3 className="text-uppercase text-center mb-4" style={{ color: '#27004b' }}>
          <b>Contact Us</b>
        </h3>

        {showPopup && (
          <div className="popup-message">
            {success && <div className="success-popup">{success}</div>}
            {error && <p style={{ color: 'white', backgroundColor: 'red' }}>{error}</p>}
          </div>
        )}

        <div className="form-group">
          <label htmlFor="name">
            <b>Full Name</b>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="form-control"
            onChange={handleChange}
            value={formData.name}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="username">
            <b>Username</b>
          </label>
          <input
            type="text"
            id="username"
            name="username"
            className="form-control"
            onChange={handleChange}
            value={formData.username}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="mobile">
            <b>Mobile</b>
          </label>
          <input
            type="number"
            id="mobile"
            name="mobile"
            className="form-control"
            onChange={handleChange}
            value={formData.mobile}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">
            <b>Email</b>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-control"
            onChange={handleChange}
            value={formData.email}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">
            <b>Message</b>
          </label>
          <textarea
            id="message"
            name="message"
            className="form-control"
            onChange={handleChange}
            value={formData.message}
            required
          />
        </div>

        <button
          type="submit"
          className="btn btn-dark w-100"
          style={{
            backgroundColor: '#27004b',
            color: '#fff500',
            padding: '10px',
            borderRadius: '10px',
            fontWeight: 'bold',
          }}
          disabled={loading || !(formData.name && formData.username && formData.mobile && formData.email && formData.message)}
        >
          {loading ? 'Sending...' : 'Send Message'}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
