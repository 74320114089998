import React from 'react'

const LoadingMini = () => {
    return (
        <div className="position-relative w-100 h-100 text-center loading"
        style={{top: "-10%", zIndex: 50}}>

            <svg width="205" height="150" viewBox="0 0 40 50">
                <polygon stroke="#27004b" strokeWidth="1" fill="none"
                points="20,1 40,40 1,40" />
                <text fill="#27004b" x="5" y="47">Loading</text>
            </svg>
            
        </div>
    )
}

export default LoadingMini
