import React, { useState, useEffect } from 'react';
import Avatar from '../../Avatar';
import { Link } from 'react-router-dom';
import moment from 'moment';
import LikeButton from '../../LikeButton';
import { useSelector, useDispatch } from 'react-redux';
import CommentMenu from './CommentMenu';
import { updateComment, likeComment, unLikeComment } from '../../../redux/actions/commentAction';
import InputComment from '../InputComment';

const CommentCard = ({ children, comment, post, commentId }) => {
    const { auth, theme } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [content, setContent] = useState('');
    const [readMore, setReadMore] = useState(false);

    const [onEdit, setOnEdit] = useState(false);
    const [isLike, setIsLike] = useState(false);
    const [loadLike, setLoadLike] = useState(false);

    const [onReply, setOnReply] = useState(false);

    useEffect(() => {
        if (comment) {
            setContent(comment.content || '');
            setIsLike(comment.likes?.some((like) => like._id === auth.user._id));
            setOnReply(false);
        }
    }, [comment, auth.user._id]);

    const handleUpdate = () => {
        if (comment && comment.content !== content) {
            dispatch(updateComment({ comment, post, content, auth }));
            setOnEdit(false);
        } else {
            setOnEdit(false);
        }
    };

    const handleLike = async () => {
        if (loadLike || !comment) return;
        setIsLike(true);

        setLoadLike(true);
        await dispatch(likeComment({ comment, post, auth }));
        setLoadLike(false);
    };

    const handleUnLike = async () => {
        if (loadLike || !comment) return;
        setIsLike(false);

        setLoadLike(true);
        await dispatch(unLikeComment({ comment, post, auth }));
        setLoadLike(false);
    };

    const handleReply = () => {
        if (onReply) return setOnReply(false);
        setOnReply({ ...comment, commentId });
    };

    const styleCard = {
        opacity: comment?._id ? 1 : 0.5,
        pointerEvents: comment?._id ? 'inherit' : 'none',
    };

    if (!comment || !comment.user) return null; // Safeguard for invalid comment data

    return (
        <div className="comment_card mt-2" style={styleCard}>
            <hr style={{ marginTop: '-1%' }} />
            <Link
                to={`/profile/${comment.user._id}`}
                className="d-flex text-dark"
                style={{ textDecoration: 'none', marginRight: '90%' }}
            >
                <Avatar src={comment.user.avatar} size="small-avatar" />
                <h6 className="mx-1" style={{ marginTop: '4px' }}>
                    <b>{comment.user.username}</b>
                </h6>
            </Link>

            <div className="comment_content">
                <div
                    className="flex-fill"
                    style={{
                        filter: theme ? 'invert(1)' : 'invert(0)',
                        color: theme ? 'white' : '#111',
                    }}
                >
                    {onEdit ? (
                        <textarea rows="5" value={content} onChange={(e) => setContent(e.target.value)} />
                    ) : (
                        <div>
                            {comment.tag && comment.tag._id !== comment.user._id && (
                                <Link
                                    to={`/profile/${comment.tag._id}`}
                                    className="mr-0"
                                    style={{ color: 'black', textDecoration: 'none' }}
                                >
                                    <b style={{ color: '#27004b' }}>@{comment.tag.username}</b>
                                </Link>
                            )}
                            <span>
                                {content.length < 100
                                    ? content
                                    : readMore
                                    ? content + ' '
                                    : content.slice(0, 100) + '....'}
                            </span>
                            {content.length > 100 && (
                                <span
                                    className="readMore"
                                    onClick={() => setReadMore(!readMore)}
                                >
                                    {readMore ? 'Hide content' : 'Read more'}
                                </span>
                            )}
                        </div>
                    )}

                    <div style={{ cursor: 'pointer' }}>
                        <small className="text-muted mr-3">{moment(comment.createdAt).fromNow()}</small>

                        <small className="font-weight-bold mr-3">
                            {comment.likes?.length || 0} love
                        </small>

                        {onEdit ? (
                            <>
                                <small
                                    className="font-weight-bold mr-3"
                                    onClick={handleUpdate}
                                >
                                    <b>Update</b>
                                </small>
                                <small
                                    className="font-weight-bold mr-3"
                                    onClick={() => setOnEdit(false)}
                                >
                                    <b>Cancel</b>
                                </small>
                            </>
                        ) : (
                            <small
                                className="font-weight-bold mr-3"
                                onClick={handleReply}
                            >
                                {onReply ? 'cancel' : 'reply'}
                            </small>
                        )}
                    </div>
                </div>

                <div className="d-flex align-items-center mx-2" style={{ cursor: 'pointer' }}>
                    <CommentMenu post={post} comment={comment} setOnEdit={setOnEdit} />
                    <LikeButton
                        isLike={isLike}
                        handleLike={handleLike}
                        handleUnLike={handleUnLike}
                    />
                </div>
            </div>

            {onReply && (
                <InputComment post={post} onReply={onReply} setOnReply={setOnReply}>
                    <Link
                        to={`/profile/${onReply.user._id}`}
                        className="mr-1"
                        style={{ textDecoration: 'none' }}
                    >
                        @{onReply.user.username}:
                    </Link>
                </InputComment>
            )}

            {children}
        </div>
    );
};

export default CommentCard;