import React from 'react';

const TermsItem = (props) => {
    return (
        <div>
            <b style={{ cursor: "pointer", color: "#27004b", whiteSpace: "nowrap" }} onClick={props.onMyselfSelect}> Terms & Condition </b>       
        </div>
    );
}

export default TermsItem;