import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { login } from '../redux/actions/authAction'
import { useDispatch, useSelector } from 'react-redux'
import Footer from "../components/footer/Footer"
import Logo from "../images/foodbyte poster (transparent).png"

const Login = () => {
    const initialState = { email: '', password: '' }
    const [userData, setUserData] = useState(initialState)
    const { email, password } = userData

    const [typePass, setTypePass] = useState(false)

    const { auth, alert } = useSelector(state => state)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if(auth.token) history.push("/")
    }, [auth.token, history])

    const handleChangeInput = e => {
        const { name, value } = e.target
        setUserData({...userData, [name]:value})
    }

    const handleSubmit = e => {
        e.preventDefault();
        
        // Validate email and password fields
        if (!email || !password) {
            alert("Both email and password are required.");
            return;
        }
    
        // Additional validation: Check if email format is correct
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            alert("Please enter a valid email address.");
            return;
        }
    
        // Dispatch login action if validation passes
        dispatch(login(userData));
    };    

    return (
        <div className="auth_page">
            <img className='logo' src={Logo} alt="Foodbyte"/>
            <div className='footer'>
                <Footer />
            </div>

            <form onSubmit={handleSubmit}>
                <h3 className="text-uppercase text-center mb-4" style={{color: "#27004b"}}><b>Sign in</b></h3>

                {/* Display Alerts */}
                {alert.loading && <div className="alert alert-info">Loading...</div>}
                {alert.success && <div className="alert alert-success">{alert.success}</div>}
                {alert.error && <div className="alert alert-danger">{alert.error}</div>}

                <div className="form-group">
                    <label htmlFor="exampleInputEmail1"> <b> Email </b> </label>
                    <input type="email" className="form-control" id="exampleInputEmail1" name="email"
                    aria-describedby="emailHelp" onChange={handleChangeInput} value={email} style={{border: "2px solid #27004b"}} />
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputPassword1"> <b> Password </b> </label>

                    <div className="pass">
                        
                        <input type={ typePass ? "text" : "password" } 
                        className="form-control" id="exampleInputPassword1"
                        onChange={handleChangeInput} value={password} name="password" style={{border: "2px solid #27004b"}} />

                        <small onClick={() => setTypePass(!typePass)} style={{marginRight: "2%"}}>
                            <b> {typePass ? 'Hide' : 'Show'} </b>
                        </small>
                    </div>
                   
                </div>
                
                <button type="submit" className="btn btn-dark w-100"
                disabled={email && password ? false : true}>
                    Login
                </button>

                <p className="forgot_btn">
                    <br />
                    <Link to="/forgot_password" style={{color: "#27004b", textDecoration: "none"}}> <b> Forgot Password </b> </Link>
                </p>

                <p className="my-2">
                    <b> Don't have an account? </b> <Link to="/register" style={{color: "#27004b", textDecoration: "none"}}> <b> Register now </b> </Link>
                </p>
            </form>
        </div>
    )
}

export default Login