import React from 'react';
import {Scrollbars} from "react-custom-scrollbars-2";

const Ads = () => {
    return (
        <div>
            <div className="ads">

                <Scrollbars className="scrollbars">

                    <h5 className="h5"> <b> Advertisement </b> </h5> <hr className="hr" style={{height: "2px", borderBottom: "2px solid white"}} />
                    
                    <a href="https://www.cyberengine.org/" target="_blank" rel="noreferrer">
                        <img src="../../assets/images/cyberengine.png" alt="Work in progress!" style={{height: "200px", width: "90%", marginLeft: "5%"} } />
                    </a> <br/> <br />

                    <a href="https://www.bkash.com/bn" target="_blank" rel="noreferrer">
                        <img src="../../assets/images/bKash.gif" alt="Work in progress!" style={{height: "250px", width: "90%", marginLeft: "5%"} } />
                    </a> <br/> <br />

                    <a href="https://www.batabd.com/" target="_blank" rel="noreferrer">
                        <img src="../../assets/images/bata.gif" alt="Work in progress!" style={{height: "250px", width: "90%", marginLeft: "5%"} } />
                    </a> <br/> <br />

                    <a href="https://www.foodpanda.com.bd/" target="_blank" rel="noreferrer">
                        <img src="../../assets/images/foodpanda.gif" alt="Work in progress!" style={{height: "250px", width: "90%", marginLeft: "5%"} } />
                    </a> <br/> <br />
                    
                    <a href="https://www.daraz.com.bd/" target="_blank" rel="noreferrer">
                        <img src="../../assets/images/daraz.gif" alt="Work in progress!" style={{height: "250px", width: "90%", marginLeft: "5%"} } />
                    </a> <br/> <br />

                    <a href="https://www.robi.com.bd/bn" target="_blank" rel="noreferrer" className='last_add'>
                        <img src="../../assets/images/robi.gif" alt="Work in progress!" style={{height: "250px", width: "90%", marginLeft: "5%"} } />
                    </a> <br /> <br />
                </Scrollbars>
            </div>
        </div>
    );
}

export default Ads;