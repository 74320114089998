import React from 'react'
import LeftSide from '../../components/message/LeftSide'

const Message = () => {
    return (
        <div className="message d-flex">
            <div className="col-md-4 border-right px-0">
                <LeftSide />
            </div>

            <div className="col-md-8 px-0 right_mess" style={{
                backgroundImage: `url(${require('../../images/burger4_01-scaled.jpg')})`,
                backgroundAttachment: 'fixed', // Fix the background image
                backgroundSize: 'cover', // Cover the entire viewport
                backgroundPosition: 'center', // Center the image
                minHeight: '100vh', // Ensure it covers the entire height  
            }}>

                {/* <div className="d-flex justify-content-center 
                align-items-center flex-column h-100">

                    <i className="fab fa-facebook-messenger"
                    style={{fontSize: '5rem', color: "#27004b"}} /> <br />
                    <h4> <b> FoodMess </b> </h4> <br />
                    <h7 style={{color: "gray"}}> A foodbyte messenger </h7>

                </div> */}
            </div>
        </div>
    )
}

export default Message
